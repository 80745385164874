import { Outlet } from "@remix-run/react";

export default function SignUpLayout() {
  return (
    <div className="flex min-h-screen w-full flex-col items-center bg-gray-200">
      <div className="flex w-full max-w-[1224px] grow flex-col">
        <div className="mt-10 flex w-full flex-col items-center justify-center gap-[8px] text-center sm:gap-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
